* {
  padding: 0;
  margin: 0;
}

body {
  background-image: url('../../assets/images/background.png');
  background-repeat: no-repeat;
  background-size: 100%;
}

.aboutus {
  display: flex;
  flex-wrap: wrap;
}

.profile {
  flex: 1;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  margin-top: 39px;
}

.profile:first-of-type {
  flex: 2;
  padding: 25px;
  background-color: white;
  margin: 20px 20px 0;
}

.profile div {
  background-color: white;
  margin: 20px;
}

@media only screen and (min-width: 530px) {
  .profile .researcher {
    align-self: flex-start;
  }
}

.profile .researcher {
  background-color: var(--GREEN-ARIA);
  width: 261px;
  border-radius: 11px;
  align-self: center;
  height: 290px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.profile .researcher .researcherTitle {
  background-color: var(--GREEN-ARIA);
  padding: 0 0 0 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
}

.profile .researcher .researcherTitle .title {
  color: white;
  font-family: 'GothamBold', sans-serif;
  font-size: 19px;
  margin-top: 6.3%;
  margin-left: 1%;
}

.profile .researcher .researcherTitle .loupe {
  margin-top: 6.5%;
}

.profile .researcher .researcherText {
  color: white;
  font-family: 'GothamLight', sans-serif;
  line-height: 21px;
  font-size: 17px;
  padding: 4% 27px 27px;
  text-align: left;
  vertical-align: bottom;
}

@media only screen and (min-width: 530px) {
  .profile .patient {
    align-self: flex-start;
  }
}

.profile .patient {
  background-color: var(--BLUE-ARIA);
  width: 261px;
  border-radius: 11px;
  align-self: center;
  height: 278px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.profile .patient .patientTitle {
  background-color: var(--BLUE-ARIA);
  padding: 0;
  padding-left: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 74px;
}

.profile .patient .patientTitle .title {
  color: white;
  font-family: 'GothamBold', sans-serif;
  font-size: 19px;
  margin-top: 6.3%;
  margin-left: 1%;
}

.profile .patient .patientTitle .loupe {
  margin-top: 6.5%;
}

.profile .patient .researcherText {
  color: white;
  font-family: 'GothamLight', sans-serif;
  line-height: 21px;
  font-size: 16.5px;
  padding: 27px;
  padding-top: 8%;
  text-align: left;
  vertical-align: bottom;
}

.needToRegister {
  flex: 1;
  min-width: 300px;
  max-width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 3%;
}

.needToRegister .text1 {
  align-self: center;
  font-family: 'GothamLight', sans-serif;
  line-height: 21px;
  font-size: 20px;
}

.needToRegister div {
  width: 70%;
  margin-top: 3%;
  align-self: center;
}

.needToRegister .text2 {
  font-family: 'GothamBold', sans-serif;
  margin-top: 3%;
  align-self: center;
  font-size: 17px;
  color: #2f2e2e;
}

.needToRegister .arrow {
  align-self: flex-end;
  margin-left: 45%;
  margin-top: 15%;
}

.welcomeBox0 {
  margin: 20px;
  width: 40%;
}

.welcomeBox0 .welcomeBox {
  min-width: 250px;
  max-width: 380px;
  padding: 25px;
  background-color: transparent;
  max-height: 800px;
  display: flex;
  flex-direction: column;
  margin-left: 16%;
  margin-top: 3%;
}

.line {
  height: 3px;
  width: 123px;
  border-radius: 100px;
  background-color: var(--GREEN-ARIA);
  margin-left: 2%;
  margin-top: 11%;
  margin-bottom: 11%;
}

.welcomeText {
  color: var(--BLUE-ARIA);
  font-family: 'GothamBold', sans-serif;
  font-size: 72px;
  text-align: left;
}

.welcomeDescription {
  text-align: left;
  font-family: 'GothamLight', sans-serif;
  line-height: 21px;
  font-size: 18px;
  margin-top: 7%;
  color: #2f2e2e;
}
