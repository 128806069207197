
.container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 39px 160px 0 160px;
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  align-items: baseline;
  margin-top: 50px;
  width: 100%;
}

.guestContent{
  display: flex;
  margin-top: 50px;
  width: 100%;
}

.tableContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.registrationDateText{
  color: var(--TEXT-GRAY-DARK);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
}


.actionsContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 100px;
}

.actionsContainerGuest{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.appTableContainerGuest{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  margin-top: 30px;
}

.appTableContainer{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: baseline;
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1300px) {

  .guestContent{
    width: 80%;
    gap: 34px;
  }

  .container{
    padding: 39px 80px 0 80px;
  }

  .appTableContainerGuest {
    width: 84%;
    margin-top: 10px;
  }

}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .guestContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

}

