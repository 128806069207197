.ahContainer {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;


::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

}

  .ahContainer2 {
    width: 80%;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-self: center;
  }

    .activityTitle {
      color: var(--BLUE-ARIA);
      font-family: 'GothamBold', sans-serif;
      font-size: 42px;
      text-align: left;
      margin-top: 1%;
    }

    .linedashboard {
      height: 3.5px;
      width: 90px;
      border-radius: 100px;
      background-color: var(--GREEN-ARIA);
      margin-top: 0.8%;
    }

    .tableHeader {
      width: 1120px;
      display: flex;
      flex-direction: row;
      margin-top: 3%;
      justify-content: center;
    }

    .tableHeader2 {
      width: 93%;
      display: flex;
      flex-direction: row;
      align-self: center;
    }

      .date0 {
        font-weight: bold;
        font-family: 'GothamBold', sans-serif;
        font-size: 17px;
        width: 18%;
        padding-bottom: 13px;
        margin-left: 2%;
        text-align: left;
      }

      .name0 {
        font-size: 17px;
        width: 35%;
        font-family: 'GothamBold', sans-serif;
        margin-left: 2%;
        text-align: left;
      }

      .activity0 {
        font-size: 17px;
        width: 47%;
        font-family: 'GothamBold', sans-serif;
        margin-left: 2%;
        text-align: left;
      }


    .table {
      width: 1120px;
      height: 426px;
      overflow-y: scroll;
      border: solid 1px #00e6a1;
      margin-top: 0.5%;
      border-radius: 24px;
      display: flex;
      flex-direction: column;
    }

    .row {
      width: 93%;
      align-self: center;
      text-align: left;
      display: flex;
      flex-direction: row;
      border: 1px none #00e6a1;
      border-bottom-style: solid;
      padding-top: 1.1%;
    }

      .date {
        font-weight: bold;
        font-family: 'GothamBold', sans-serif;
        font-size: 17px;
        width: 18%;
        padding-bottom: 13px;
        margin-left: 2%;
      }

      .name {
        font-size: 17px;
        width: 35%;
        font-family: 'GothamLight', sans-serif;
        margin-left: 2%;
      }

      .activity {
        font-size: 17px;
        width: 47%;
        font-family: 'GothamLight', sans-serif;
        margin-left: 2%;
      }



  .paginations {
    width: 1120px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 33px;
    margin-bottom: 66px;
  }

    .results {
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      color: var(--TEXT-GRAY-DARK);
      align-self: center;
      margin-right: 2%;
    }


