.container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 39px 160px 0 160px;
  width: 100%;
  height: 100%;
}
.chatcontainer {
  width: 100%;
  display: flex;
  align-items: stretch;
  overflow: auto;
}

.chatcontainer::-webkit-scrollbar {
  display: none;
}

.chatchatbot {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

@media only screen and (min-width: 530px) {
  .box {
    align-self: flex-start;
  }
}

.contentBox{
  display:flex;
}

.box {
  background-color: var(--GREEN-FLUOR);
  min-width: 208px;
  width: 208px;
  border-radius: 11px;
  align-self: center;
  height: 209px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 5%;
}

.chatbotbox .box {
  height: 70%;
  margin: 0;
  display: flex;
  justify-content: center;
}

.chatbotbox .box img {
  align-self: center;
}

.chatbotbox p {
  color: white;
  font-family: 'GothamBold', sans-serif;
  font-size: 20px;
}

.chatbotbox .researcherTitle {
  background-color: var(--GREEN-ARIA);
  padding: 0 0 0 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
}

.profilecontainer {
  width: 45%;
  margin-top: 6%;
  min-width: 450px;
  align-self: center;
  display: flex;
}

.itembox {
  background-color: var(--GREEN-FLUOR);
  min-width: 208px;
  width: 208px;
  border-radius: 11px;
  align-self: center;
  height: 209px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 5%;
  cursor: pointer;
}

.itembox .box {
  height: 70%;
  margin: 0;
  display: flex;
  justify-content: center;
}

.itembox .box img {
  align-self: center;
}

.itembox p {
  color: white;
  font-family: 'GothamBold', sans-serif;
  font-size: 20px;
}

.itembox .researcherTitle {
  background-color: var(--GREEN-ARIA);
  padding: 0 0 0 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
}

.patientBox {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.chaticon {
  margin-left: 30px;
  margin-top: 0.8%;
  margin-right: 13px;
}

.patientBox2 {
  display: flex;
  flex-direction: row;
  padding-top: 15px;
}

.patientboxMsg {
  background-color: #c2e8ff;
  padding: 14px 25px 14px 14px;
  line-break: anywhere;
  font-size: 14px;
  text-align: left;
  border-radius: 8px;
  margin-left: -4.8px;
  color: var(--TEXT-GRAY-DARK);
  font-family: 'GothamLight', sans-serif;
}

.patientBoxRight {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}

.patientBoxRight2 {
  display: flex;
  flex-direction: row;
  max-width: 85%;
}

.patientboxMsgRight {
  background-color: #b9fee9;
  line-break: anywhere;
  padding: 14px 25px 14px 14px;
  font-size: 14px;
  text-align: left;
  border-radius: 8px;
  color: var(--TEXT-GRAY-DARK);
  font-family: 'GothamLight', sans-serif;
  margin-right: -4.8px;
}

.chatmeicon {
  margin-top: 0.8%;
  margin-right: 30px;
  margin-left: 13px;
}

.chatchatbot {
  width: 75%;
  min-width: 500px;
  align-self: center;
}

.chatTitle {
  color: var(--BLUE-ARIA);
  font-family: 'GothamBold', sans-serif;
  font-size: 42px;
  text-align: left;
  margin-top: 2%;
}

.linechat {
  height: 3.5px;
  width: 100px;
  border-radius: 100px;
  background-color: var(--GREEN-ARIA);
  margin-left: 0.5%;
  margin-top: 0.8%;
}

.chatbox {
  width: 100%;
  height: 700px;
  margin-top: 29px;
  margin-bottom: 35px;
  border: solid 1px var(--GREEN-ARIA);
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.boxScroll {
  height: 84%;
  width: 100%;
  align-self: center;
  padding-top: 3%;
  overflow-y: scroll;
  margin-bottom: 3%;
}

.msgBox {
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 3%;
  padding-left: 6%;
}

.msgBoxMe {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 3%;
  padding-right: 6%;
}

.bubble {
  background-color: green;
}

.text {
  color: var(--TEXT-GRAY-DARK);
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

.boxWrite {
  height: 40px;
  border-radius: 24px;
  border: solid 1px var(--GREEN-ARIA);
  align-self: center;
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 1%;
}

.input {
  border: none;
  width: 78%;
  margin-left: 2%;
  outline: none;
  background-color: rgba(255, 255, 255, 0.6);
  font-family: Roboto, sans-serif;
}

.input::placeholder {
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.046px;
  color: var(--TEXT-GRAY-LIGHT);
}

.img {
  align-self: center;
  margin-right: 3%;
  cursor: pointer;
}

.patientBox2 {
  justify-content: flex-start;
}

.patientBox3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .chatchatbot {
    width: 90%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }


  .chatbox {
    align-self: center;
    margin-top: 1.5% !important;
  }

  .chatTitle {
    width: 100%;
    margin-left: 0.5% !important;
  }

  .linechat0 {
    width: 100%;
  }

  .linechat {
    margin-left: 0.7% !important;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .contentBox{
    flex-direction: column;
  }
}

