$font-family: 'Montserrat', sans-serif;
$color-white: #FFF;

.footerContainer{
    background-color: var(--GREEN-ARIA);
    text-align: center;
    align-self: flex-end;
    margin-top: auto;
    width: 100%;
    padding: 13px 74px 13px 0 !important;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-decoration: none;

    .contentTexts{
        background-color: transparent;
        display: flex;
        justify-content: flex-end;
        gap: 24px;
    }
    .footerText{
        cursor: pointer;
        color: $color-white;
        font-family: $font-family;
        font-size: 13px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-decoration: none !important;

        &:hover{
            background-color: transparent;
            font-size: 15px;
            transition: 0.5s;
            text-decoration: none;
        }
    }

    .divider{
        width: 1.5px;
        height: 16px;
        background-color: $color-white !important;
    }
}

