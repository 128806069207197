.chatbot {
  width: 276px;
  height: 325px;
  background-color: white;
  border-width: 5px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
}

.chatbot .header {
  background-color: #00e6a1;
  padding: 0px 14px;
  width: 100%;
  height: 17%;
  position: relative !important;
  margin: 0;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.chatbot .title {
  color: white;
  align-self: flex-start;
  text-align: left;
  font-family: 'GothamLight', sans-serif;
  margin-top: 6.5%;
  margin-left: 6.5%;
  font-size: 16px;
}

.chatbot .icon {
  color: white;
  margin-top: 6.5%;
  margin-right: 6%;
  cursor: pointer;
}

.chatbot .scrollBox {
  height: 58%;
  margin: 8%;
}

.chatbot .scrollBox p {
  color: black;
  font-size: 15px;
  text-align: left;
}

.chatbot .textInput {
  border: solid 1px #00e6a1;
  border-radius: 24px;
  height: 40px;
  margin-top: -4%;
  display: flex;
  justify-content: space-between;
  width: 90%;
  align-self: center;
}

.chatbot .textInput input {
  border: none;
  width: 78%;
  margin-left: 7%;
  outline: none;
}

.chatbot .textInput img {
  align-self: center;
  margin-right: 3%;
  cursor: pointer;
}

.patientBox {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

  .chaticon {
    margin-left: 30px;
    margin-top: 0.8%;
    margin-right: 13px;
  }

  .patientBox2 {
    display: flex;
    flex-direction: row;
    padding-top: 15px;
  }

  .patientboxMsg {
    background-color: #c2e8ff;
    padding: 14px 25px 14px 14px;
    line-break: anywhere;
    font-size: 14px;
    text-align: left;
    border-radius: 8px;
    margin-left: -4.8px;
    color: var(--TEXT-GRAY-DARK);
    font-family: 'GothamLight', sans-serif;
  }


.patientBoxRight {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}

  .patientBoxRight2 {
    display: flex;
    flex-direction: row;
    max-width: 85%;
  }

  .patientboxMsgRight {
    background-color: #b9fee9;
    line-break: anywhere;
    padding: 14px 25px 14px 14px;
    font-size: 14px;
    text-align: left;
    border-radius: 8px;
    color: var(--TEXT-GRAY-DARK);
    font-family: 'GothamLight', sans-serif;
    margin-right: -4.8px;
  }

  .chatmeicon {
    margin-top: 0.8%;
    margin-right: 30px;
    margin-left: 13px;
  }


.container {
  position: fixed;
  right: 10px;
  bottom: 2%;
}

  .img {
    position: absolute;
    right: 10px;
    bottom: 21px;
    cursor: pointer;
  }

