.modalBox {
  display: flex;
  flex-direction: row;
  width: 500px;
  justify-content: space-between;

  .text {
    padding-left: 10px;
  }

  .boxicon {
    cursor: pointer;
    .icon {
      color: grey;
      align-self: center;
    }
  }
}

.modalBox2 {
  padding-left: 14px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;

  .expire {
    margin-top: 1%;
  }

  .continue {
    margin-top: 2%;
  }

  .link {
    align-self: center;
    margin-top: 1%;
  }
}

.buttonBox {
  display: flex;
  flex-direction: column;

  .button {
    text-align: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    background-color: var(--GREEN-ARIA);
    color: white;
    border-radius: 24px;
    gap: 8px;
    width: 220px;
    height: 40px;
    font-size: 16px;
    font-family: 'GothamBold', sans-serif;
    align-self: center;
    margin-top: 10%;
    align-items: center;
  }
}
