.supportContainer {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.supportContainer2 {
  width: 80%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.supportTitle {
  color: var(--BLUE-ARIA);
  font-family: 'GothamBold', sans-serif;
  font-size: 42px;
  text-align: left;
  margin-top: 4%;
}

.linedashboard {
  height: 3.5px;
  width: 90px;
  border-radius: 100px;
  background-color: var(--GREEN-ARIA);
  margin-top: 0.8%;
}

.itemboxAbout {
  background-color: white;
  min-width: 208px;
  width: 208px;
  border-radius: 11px;
  height: 209px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: solid 1px var(--BLUE-ARIA);
}

.itemboxAbout img {
  align-self: center;
  margin-top: 11%;
}

.itemboxAbout p {
  color: var(--BLUE-ARIA);
  font-family: GothamBold, sans-serif;
  font-size: 20px;
}

.itemboxAbout .researcherTitle {
  background-color: var(--GREEN-ARIA);
  padding: 0 0 0 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
}

.itembox2 {
  background-color: var(--GREEN-FLUOR);
  min-width: 208px;
  width: 208px;
  border-radius: 11px;
  height: 209px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-left: 2%;
}

.itembox2 .box {
  height: 70%;
  margin: 0;
  display: flex;
  justify-content: center;
}

.itembox2 .box img {
  align-self: center;
}

.itembox2 p {
  color: white;
  font-family: 'GothamBold', sans-serif;
  font-size: 20px;
}

.itembox2 .researcherTitle {
  background-color: var(--GREEN-ARIA);
  padding: 0 0 0 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
}

.boxes {
  margin-top: 4.3%;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 1018px) {
  .supportContainer .supportContainer2 .linedashboard0 .boxes {
    flex-direction: column;
  }

  .supportContainer .supportContainer2 .linedashboard0 .boxes .itembox2 {
    margin-left: 0;
    margin-top: 2%;
    align-self: center;
  }

  .supportContainer .supportContainer2 .linedashboard0 .boxes .itemboxAbout {
    align-self: center;
  }
}
