* {
  padding: 0;
  margin: 0;
}

body {
  background-image: url('../../assets/images/background.png');
  background-repeat: no-repeat;
  background-size: 100%;
}

.about {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
}

.partnersBox .line {
  width: 1px;
  background-color: var(--GREEN-ARIA);
  height: 30px;
  align-self: center;
}

.img {
  max-width: 100%;
  height: auto;
}

.partnersBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border: solid 1px var(--GREEN-ARIA);
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  margin: 3% 160px;
  padding: 22px 41px;
}

.partnerItem {
  display: flex;
  align-items: center;
  margin: 5px; /* Espaciado entre elementos */
}

.img {
  max-width: 100%;
  height: auto;
}

.about .firstContainer {
  display: flex;
  flex-direction: row;
}

.box {
  background-color: var(--GREEN-FLUOR);
  min-width: 208px;
  width: 208px;
  border-radius: 11px;
  align-self: center;
  height: 209px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 5%;
}

@media only screen and (max-width: 914px) {
  .about .firstContainer {
    flex-direction: column;
  }

  .aboususBox {
    align-self: center;
  }
}

.profilecontainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 45%;
  margin-top: 6%;
  min-width: 450px;
  align-self: center;
}

.chatbotbox .box {
  height: 70%;
  margin: 0;
  display: flex;
  justify-content: center;
}

.chatbotbox .box img {
  align-self: center;
}

.chatbotbox p {
  color: white;
  font-family: 'GothamBold', sans-serif;
  font-size: 20px;
}

.chatbotbox .researcherTitle {
  background-color: var(--GREEN-ARIA);
  padding: 0 0 0 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
}
@media only screen and (min-width: 530px) {
  .profilecontainer .dashboard {
    align-self: flex-start;
  }
}

.profilecontainer .dashboard {
  background-color: var(--BLUE-ARIA);
  min-width: 208px;
  width: 208px;
  border-radius: 11px;
  align-self: center;
  height: 209px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.profilecontainer .dashboard .box {
  height: 70%;
  margin: 0;
  display: flex;
  justify-content: center;
}

.profilecontainer .dashboard .box img {
  align-self: center;
}

.profilecontainer .dashboard p {
  color: white;
  font-family: 'GothamBold', sans-serif;
  font-size: 20px;
}

.profilecontainer .dashboard .researcherTitle {
  background-color: var(--GREEN-ARIA);
  padding: 0 0 0 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
}

.aboususBox {
  min-width: 250px;
  background-color: transparent;
  padding: 25px;
  max-height: 800px;
  display: flex;
  flex-direction: column;
  margin-top: 3%;
  width: 55%;
}

.lineabout {
  height: 3.5px;
  width: 125px;
  border-radius: 100px;
  background-color: var(--GREEN-ARIA);
  margin-top: 1.5%;
  margin-left: 14%;
}

.aboutUSText {
  color: var(--BLUE-ARIA);
  font-family: 'GothamBold', sans-serif;
  font-size: 42px;
  text-align: left;
  margin-left: 14%;
}

.aboutUSDescription {
  text-align: left;
  font-family: 'GothamLight', sans-serif;
  line-height: 24px;
  font-size: 16px;
  margin-top: 8%;
  margin-left: 14%;
  width: 83%;
  letter-spacing: -0.2px;
  color: #2f2e2e;
}

@media screen and (max-width: 863px) {
  .aboususBox {
    width: 75%;
  }
}

@media screen and (max-width: 518px) {
  .aboususBox {
    width: 95%;
  }

  .line {
    display: none;
  }
}

.partnerItem img {
  align-self: center;
}

@media screen and (max-width: 863px) {
  .partnersBox {
    flex-direction: row;
  }

  .partnersBox .line {
    width: 30px;
    background-color: var(--GREEN-ARIA);
    height: 1px;
    align-self: center;
  }
}

/* Estilos para pantallas más grandes */
@media (min-width: 768px) {
  .partnersBox {
    display: flex;
    justify-content: space-between;
  }

  .img {
    max-width: 100%;
    height: auto;
  }
}

/* Estilos para pantallas más pequeñas */
@media (max-width: 768px) {
  .partnerItem {
    flex-basis: 50%; /* Cambia el ancho de los elementos a la mitad en pantallas pequeñas */
  }
  .line {
    display: none;
  }
}

@media (max-width: 960px) {
  .line {
    display: none;
  }
}
