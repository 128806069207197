.container {
  width: 100%;
  margin-top: 49px;
}

.content {
  display: flex;
  justify-content: space-between;
}

.contentGuest{
  display: flex;
  margin-top: 50px;
  width: 100%;
}

.button {
  border-radius: 24px !important;
  cursor: pointer !important;
  color: #fff !important;
  font-feature-settings:
    'clig' off,
    'liga' off !important;
  font-family: Montserrat, sans-serif !important;
  font-size: 16px ;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  transition: all 0.3s ease-in-out !important;
  align-items: center !important;
  gap: 3px ;
  align-self: stretch !important;
  text-transform: none !important;
  width: 166px;
  height: 40px;
  padding: 8px;
}

.buttonGreen {
  background: var(--GREEN-ARIA) !important;
  margin-left: 32px ;
}

.buttonCancel {
  background: transparent !important;
  color: var(--GREEN-ARIA) !important;
  border: 1px solid var(--GREEN-ARIA) !important;
  margin-right: 8px !important;
}

.preview {
  margin-top: 24px;
  margin-bottom: 86px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dbdada;
  overflow: auto;
  background-color: #ffffff;
}

.imagePreview {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.notViewDocument {
  font-family:
    Gotham Rounded,
    sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ccd3d3;
}

@media (max-width: 1350px){

  .boxPreview{
    width:70%;
    margin-top:0;
    margin-left:10px
  }

}

@media (max-width: 1200px){

  .button{
    width: 120px;
    font-size: 14px ;
    gap: 1px ;
  }

  .buttonGreen{
    margin-left: 16px;
  }

}

@media (max-width: 991.98px) {
  .contentGuest{
    flex-direction: column;
    margin-top: 50px;
    width: 100%;
  }

  .boxPreview{
    width:100%;
    margin-top:0;
    margin-left:10px
  }
}
