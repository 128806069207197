:root {
  --BLUE-ARIA:#01BFD5;
  --GREEN-FLUOR: #82FB56;
  --GREEN-LIGHT:#7DEFCD;
  --GREEN-ARIA: #00e6a1;
  --GREEN-DARK:#27B188;
  --RED-LIGHT:#E34D4E;
  --RED:#CF3031;
  --RED-DARK:#990B0C;
  --TEXT-GRAY-DARK:#454545;
  --TEXT-GRAY-LIGHT:#B6B5B5;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



@font-face {
  font-family: 'GothamLight'; /*Can be any text*/
  src:
    local('GothamRoundedLight_21020'),
    url('./assets/fonts/GothamRoundedLight_21020.ttf') format('truetype');
}

@font-face {
  font-family: 'GothamBold'; /*Can be any text*/
  src:
    local('GothamRounded-Bold'),
    url('./assets/fonts/GothamRounded-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'GothamMedium'; /*Can be any text*/
  src:
    local('GothamRoundedMedium_21022-Bold'),
    url('./assets/fonts/GothamRoundedMedium_21022.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat'; /*Can be any text*/
  src:
    local('Montserrat-Light'),
    url('./assets/fonts/montserrat/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'MontserratSemiBold'; /*Can be any text*/
  src:
          local('Montserrat-SemiBold'),
          url('./assets/fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Light'; /*Can be any text*/
  src:
    local('Roboto-Light'),
    url('./assets/fonts/roboto/Roboto-Light.ttf') format('truetype');
}
