.dashboardContainer {
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-right: 160px;
  margin-left: 160px;
}

.containerMedicalRecord {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 39px 160px 0 160px;
  width: 100%;
  height: 100%;
}

.titleMedicalRecord {
  font-family:
    Gotham Rounded,
    sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--BLUE-ARIA);
}

::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.dashboard {
  min-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashTitle {
  color: var(--BLUE-ARIA);
  font-family: 'GothamBold', sans-serif;
  font-size: 42px;
  text-align: left;
  margin-left: 14%;
  margin-top: 4%;
}

.linedashboard {
  height: 3.5px;
  width: 90px;
  border-radius: 100px;
  background-color: var(--GREEN-ARIA);
  margin-top: 0.8%;
  margin-left: 14%;
}

@media screen and (max-width: 1250px) {
  .dashboard {
    width: 100%;
  }

  .dashTitle {
    margin-left: 0% !important;
    width: 53.5%;
    align-self: center;
  }

  .linedashboard0 {
    width: 53.5%;
    align-self: center;
  }

  .linedashboard {
    margin-left: 0% !important;
  }
}

@media only screen and (min-width: 530px) {
  .box {
    align-self: flex-start;
  }
}

.box {
  background-color: var(--GREEN-FLUOR);
  min-width: 208px;
  width: 208px;
  border-radius: 11px;
  align-self: center;
  height: 209px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 5%;
}

.chatbotbox .box {
  height: 70%;
  margin: 0;
  display: flex;
  justify-content: center;
}

.chatbotbox .box img {
  align-self: center;
}

.chatbotbox p {
  color: white;
  font-family: 'GothamBold', sans-serif;
  font-size: 20px;
}

.chatbotbox .researcherTitle {
  background-color: var(--GREEN-ARIA);
  padding: 0 0 0 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
}

.profilecontainer {
  width: 45%;
  margin-top: 6%;
  min-width: 450px;
  align-self: center;
  display: flex;
}

.itembox {
  background-color: var(--GREEN-FLUOR);
  min-width: 208px;
  width: 208px;
  border-radius: 11px;
  align-self: center;
  height: 209px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 5%;
  cursor: pointer;
}

.itembox .box {
  height: 70%;
  margin: 0;
  display: flex;
  justify-content: center;
}

.itembox .box img {
  align-self: center;
}

.itembox p {
  color: white;
  font-family: 'GothamBold', sans-serif;
  font-size: 20px;
}

.itembox .researcherTitle {
  background-color: var(--GREEN-ARIA);
  padding: 0;
  padding-left: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
}

@media only screen and (max-width: 1250px) {
  .profilecontainer .dashboard {
    align-self: flex-start;
  }

  .profilecontainer .itembox {
    margin-top: 3% !important;
  }
}

@media only screen and (max-width: 730px) {
  .profilecontainer {
    flex-direction: column;
  }
}

.profilecontainer .dashboard {
  background-color: var(--BLUE-ARIA);
  min-width: 208px;
  width: 208px;
  border-radius: 11px;
  align-self: center;
  height: 209px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.profilecontainer .dashboard .box {
  height: 70%;
  margin: 0;
  display: flex;
  justify-content: center;
}

.profilecontainer .dashboard .box img {
  align-self: center;
}

.profilecontainer .dashboard p {
  color: white;
  font-family: 'GothamBold', sans-serif;
  font-size: 20px;
}

.profilecontainer .dashboard .researcherTitle {
  background-color: var(--GREEN-ARIA);
  padding: 0;
  padding-left: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
}

.chat {
  width: 40%;
  min-width: 500px;
}

.chatTitle {
  color: var(--BLUE-ARIA);
  font-family: 'GothamBold', sans-serif;
  font-size: 42px;
  text-align: left;
  margin-top: 6%;
}

.linechat {
  height: 3.5px;
  width: 90px;
  border-radius: 100px;
  background-color: var(--GREEN-ARIA);
  margin-left: 2%;
  margin-top: 0.8%;
}

.chatbox {
  width: 436px;
  height: 450px;
  margin-top: 6%;
  border: solid 1px var(--GREEN-ARIA);
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  padding: 0 0 14px 0;
}

.boxScroll {
  height: 90%;
  width: 100%;
  align-self: center;
  overflow-y: scroll;
  padding-bottom: 40px;
}

.msgBox {
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 3%;
  padding-left: 6%;
}

.bocataChatbotVerde {
  align-items: flex-start;
  display: flex;
  position: relative;
  width: 268px;
}

.bocataChatbotVerde .frame {
  align-items: flex-start;
  align-self: stretch;
  background-color: #b9fde9;
  border-radius: 8px 0px 8px 8px;
  display: flex;
  flex-grow: 1;
  gap: 8px;
  padding: 16px;
  position: relative;
}

.bocataChatbotVerde-verde .loremIpsumAte {
  color: var(--TEXT-GRAY-DARK);
  flex: 1;
  margin-top: -1px;
  position: relative;
  text-align: right;
  font-size: 15px;
}

.bocataChatbotVerde .rectangle {
  height: 15px;
  position: relative;
  width: 16px;
}

.bocataChatbotAZUL {
  align-items: flex-start;
  display: flex;
  position: relative;
  width: 247px;
}

.bocataChatbotAZUL .rectangle {
  height: 15px;
  position: relative;
  width: 16px;
}

.bocataChatbotAZUL .frame {
  align-items: flex-start;
  align-self: stretch;
  background: #c2e8ff;
  border-radius: 0px 8px 8px 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-grow: 1;
  gap: 8px;
  justify-content: flex-end;
  padding: 16px;
  position: relative;
}

.bocataChatbotAZUL .loremIpsumAte {
  color: var(--TEXT-GRAY-DARK);
  flex: 1;
  margin-top: -1px;
  position: relative;
  text-align: left;
  font-size: 15px;
}

.msgBoxMe {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 3%;
  padding-right: 6%;
}

.bubble {
  background-color: green;
}

.text {
  color: var(--TEXT-GRAY-DARK);
  font-size: 16px;
  font-family: Montserrat, sans-serif;
}

.boxWrite {
  height: 40px;
  border-radius: 24px;
  border: solid 1px var(--GREEN-ARIA);
  align-self: center;
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.inputChatDashboard {
  border: none;
  width: 78%;
  margin-left: 7%;
  outline: none;
  background-color: rgba(255, 255, 255, 0.6);
}

.inputChatDashboard::placeholder {
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.046px;
  color: var(--TEXT-GRAY-LIGHT);
}

.img {
  align-self: center;
  margin-right: 3%;
  cursor: pointer;
}

@media screen and (max-width: 1650px) {
  .dashboardContainer {
    margin-right: 0.9vw;
    margin-left: 0.9vw;
  }
}

@media screen and (max-width: 1350px) {
  .dashboardContainer {
    margin-right: 0.5vw;
    margin-left: 0.5vw;
  }
}

@media screen and (max-width: 1250px) {
  .chat {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 2.5%;
  }

  .profilecontainer {
    width: 100%;
    min-width: 450px;
    align-self: center;
    display: flex;
    justify-content: center;
    margin-top: 0;
  }

  .dashboard {
    margin-top: 0;
  }

  .chatbox {
    align-self: center;
    margin-top: 3.5% !important;
  }

  .chatTitle {
    margin-left: 0 !important;
    width: 36%;
    align-self: center;
  }

  .linechat0 {
    width: 36%;
    align-self: center;
  }

  .linechat {
    margin-left: 0 !important;
  }
}
