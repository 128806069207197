.container{
  display: flex;
  padding-left: 100px; /* Usamos un porcentaje en lugar de un valor fijo */
  padding-right: 100px; /* Usamos un porcentaje en lugar de un valor fijo */
  padding-top: 75px;
  width: 100%;
}

.containerGuest{
  display: flex;
  width: 100%;
}

/* Estilos para pantallas más pequeñas (por ejemplo, hasta 1250px) */
@media (max-width: 1250px) {
  .container{
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 40px;
  }
}

@media (max-width: 1400px) {
  .container{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
  }
}

@media (max-width: 950px) {
  .container{
    flex-direction: column;
    overflow-y: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
  }
}
