.title {
  font-family:
          Gotham Rounded,
          sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--BLUE-ARIA);
}
