* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  height: 100vh;
  text-align: center;
}

.modalBox {
  display: flex;
  flex-direction: row;
  width: 500px;
  justify-content: space-between;
}

  .text {
    padding-left: 10px;
  }

  .iconBox {
    cursor: pointer;
  }

    .icon {
      color: grey;
      align-self: center;
    }



.modalDescriptionBox {
  padding-left: 14px;
  padding-top: 16px;
  padding-right: 14px;
  width: 500px;
  display: flex;
  flex-direction: column;
}

  .text {
    margin-bottom: 3%;
  }

  .aclass {
    align-self: center;
    color: var(--GREEN-ARIA);

  }

  .aclass:hover {
    color: var(--BLUE-ARIA) !important;
    transition:  0.5s ease-in-out;
  }

  .aclass:visited{
    color: var(--GREEN-ARIA);
  }


.modal {
  display: flex;
  flex-direction: column;
}

  .buttonclose {
    text-align: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    background-color: var(--GREEN-ARIA);
    color: white;
    border-radius: 24px;
    gap: 8px;
    width: 206px;
    height: 40px;
    font-size: 16px;
    font-family: 'GothamBold', sans-serif;
    align-self: center;
    margin-top: 10%;
    align-items: center;
  }

@media (max-width: 700px) {
  .App {
    width: 100% !important; /* Asegurar que ocupe todo el ancho en pantallas pequeñas */
  }
}
