.container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 39px 160px 0 160px;
  width: 100%;
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-top: 50px;
    width: 100%;
  }
}

.registrationDateText{
  color: var(--TEXT-GRAY-DARK);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}

