.button {
  border-radius: 24px !important;
  cursor: pointer !important;

  color: #fff !important;
  font-feature-settings:
    'clig' off,
    'liga' off !important;
  font-family: Montserrat, sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  transition: all 0.3s ease-in-out !important;
  display: flex !important;
  justify-content: flex-start !important;
  height: 40px !important;
  padding: 8px !important;
  align-items: center !important;
  gap: 3px !important;
  align-self: stretch !important;
  text-transform: none !important;
}

.buttonGreen {
  background: #00e6a1 !important;
}

.buttonRed {
  background: var(--RED-LIGHT) !important;
}

.divider {
  border-color: var(--BLUE-ARIA) !important;
  width: 100% !important;
  margin-top: 8px !important;
  margin-bottom: 35px !important;
}

.dividerBottom {
  border-color: var(--BLUE-ARIA) !important;
  width: 100% !important;
  margin-top: 24px !important;
  margin-bottom: 35px !important;
}

.actionsContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 100px;
}

.actionGuestSection{
  display:flex;
  justify-content: space-between;
  width: 100%;
}

.actionsTitle {
  font-family:
    Gotham Rounded,
    sans-serif;
  text-align: left;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--BLUE-ARIA);
}

.icon {
  display: flex;
  width: 24px;
  height: 32px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.text {
  padding: 0 8px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
