.profileI {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;

  .inviteTitle {
    width: 35%;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    margin-left: 5%;
  }

  .inviteBox {
    width: 60%;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1%;
  }

  .dashTitle {
    color: var(--BLUE-ARIA);
    font-family: 'GothamBold', sans-serif;
    font-size: 42px;
    text-align: left;
    margin-left: 14%;
    margin-top: 4%;
  }

  .inviteTitle2 {
    color: var(--BLUE-ARIA);
    font-family: 'GothamBold', sans-serif;
    font-size: 32px;
    text-align: center;
    width: 80%;
    align-self: center;
    margin-top: 2%;
  }

  .linedashboard {
    height: 3.5px;
    width: 90px;
    border-radius: 100px;
    background-color: var(--GREEN-ARIA);
    margin-top: 0.8%;
    margin-left: 14%;
  }

  .arrow {
    align-self: center;
    margin-top: 5%;
  }

  .inviteBox2 {
    border: solid 1px var(--GREEN-ARIA);
    border-radius: 24px;
    width: 778px;
    height: 630px;
    display: flex;
    flex-direction: column;
  }

  .inviteBox2 .form {
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .inviteBox2 .form {
    margin-top: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .inviteBox2 .forgotPassword {
    color: var(--GREEN-ARIA);
    font-size: 13px;
    font-family: 'GothamBold', sans-serif;
    margin-top: 4%;
    width: 74%;
    text-align: right;
    align-self: center;
    cursor: pointer;
  }

  .imgInvite {
    margin-top: 14%;
    margin-bottom: 3%;
    align-self: center;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    margin-top: 10%;
    width: 70%;
    justify-content: space-between;
  }

  .buttonCancel {
    background-color: white;
    border: solid 1px var(--GREEN-ARIA);
    border-radius: 24px;
    gap: 8px;
    width: 130px;
    height: 40px;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.05px;
    font-family: 'GothamBold', sans-serif;
    align-self: center;
    margin-top: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--GREEN-ARIA);
  }

  .buttonCancel:hover {
    color: #e8e8e8;
  }

  .buttonCancel:hover::after {
    height: 0px;
  }

  .buttonSave {
    background-color: var(--GREEN-ARIA);
    color: white;
    border-radius: 24px;
    gap: 8px;
    width: 130px;
    height: 40px;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.05px;
    font-family: 'GothamBold', sans-serif;
    align-self: center;
    margin-top: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .buttonSave:hover {
    color: #e8e8e8;
  }

  .buttonSave:hover::after {
    height: 0;
  }
}

@media screen and (max-width: 1111px) {
  .profileI .inviteTitle {
    width: 100%;
  }

  .profileI .inviteBox {
    width: 100%;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .profileI .inviteBox .inviteBox2 {
    align-self: center;
  }
}

.profileI .inviteBox .inviteBox2 .registerButton {
  background-color: var(--GREEN-ARIA);
  color: white;
  border-radius: 24px;
  gap: 8px;
  width: 206px;
  height: 40px;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.05px;
  font-family: 'GothamBold', sans-serif;
  align-self: center;
  margin-top: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.profileI .inviteBox .inviteBox2 .registerButton:hover {
  color: #e8e8e8;
}

.profileI .inviteBox .inviteBox2 .registerButton:hover::after {
  height: 0;
}

.profileI .inviteBox .inviteBox2 .willreceiveCode {
  color: var(--GREEN-ARIA);
  margin-top: 3%;
  font-size: 15px;
  letter-spacing: -0.05px;
}

