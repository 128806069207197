.searchContainer {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

/* Estilos para pantallas más pequeñas */
@media (max-width: 1300px) {
  .responsiveTable {
    width: 100%;
    overflow-x: auto;
  }
  .responsiveTable th,
  .responsiveTable td {
    white-space: nowrap; /* Evita que el texto se ajuste automáticamente */
  }
  /* Ajusta los estilos de las filas y celdas según sea necesario */
  .responsiveTable th {
    /* Estilos para encabezados en pantallas pequeñas */
  }
  .responsiveTable td {
    /* Estilos para celdas en pantallas pequeñas */
  }
}
