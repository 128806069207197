.profileC {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;

  .profileTitle {
    width: 50%;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    margin-left: 5%;
  }

  .profileBox {
    width: 45%;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 29px;
    margin-bottom: 35px;
  }

  .dashTitle {
    color: var(--BLUE-ARIA);
    font-family: 'GothamBold', sans-serif;
    font-size: 42px;
    text-align: left;
    margin-left: 14%;
    margin-top: 4%;
  }

  .dashTitle2 {
    color: var(--BLUE-ARIA);
    font-family: 'GothamBold', sans-serif;
    font-size: 24px;
    align-self: center;
    text-align: center;
  }

  .linedashboard {
    height: 3.5px;
    width: 90px;
    border-radius: 100px;
    background-color: var(--GREEN-ARIA);
    margin-top: 0.8%;
    margin-left: 14%;
  }

  .arrow {
    align-self: center;
    margin-top: 5%;
  }

  .profileBox2 {
    border: solid 1px var(--GREEN-ARIA);
    border-radius: 24px;
    width: 440px;
    height: 649px;
  }

  .profileBox2 .form {
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .profileBox2.form .input {
    border: solid 1px var(--GREEN-ARIA);
    width: 75%;
    height: 45px;
    border-radius: 24px;
    margin-top: 3%;
  }

  .profileBox2 .form .input[type='text'] {
    padding-left: 4.5%;
  }

  .profileBox2 .form .input[type='password'] {
    padding-left: 4.5%;
  }

  .profileBox2 .form {
    margin-top: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .profileBox2 .forgotPassword {
    color: var(--GREEN-ARIA);
    font-size: 13px;
    font-family: 'GothamBold', sans-serif;
    margin-top: 4%;
    width: 74%;
    text-align: right;
    align-self: center;
    cursor: pointer;
  }

  .profileBox2 .form .input {
    border: solid 1px var(--GREEN-ARIA);
    width: 75%;
    height: 45px;
    border-radius: 24px;
    margin-top: 3.5%;
  }

  .imgProfile {
    margin-top: 8%;
    margin-bottom: 3%;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    margin-top: 6%;
    width: 70%;
    justify-content: space-between;
  }

  .buttonCancel {
    background-color: white;
    border: solid 1px var(--GREEN-ARIA);
    border-radius: 24px;
    gap: 8px;
    width: 130px;
    height: 40px;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.05px;
    font-family: 'GothamBold', sans-serif;
    align-self: center;
    margin-top: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--GREEN-ARIA);
  }

  .buttonCancel:hover {
    color: #e8e8e8;
  }

  .buttonCancel:hover::after {
    height: 0;
  }

  .buttonSave {
    background-color: var(--GREEN-ARIA);
    color: white;
    border-radius: 24px;
    gap: 8px;
    width: 130px;
    height: 40px;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.05px;
    font-family: 'GothamBold', sans-serif;
    align-self: center;
    margin-top: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .buttonSave:hover {
    color: #e8e8e8;
  }

  .buttonSave:hover::after {
    height: 0;
  }
}

@media screen and (max-width: 1111px) {
  .profileC .profileTitle {
    width: 100%;
  }

  .profileC .profileBox {
    width: 100%;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .profileC .profileBox .profileBox2 {
    align-self: center;
  }
}
