.container {
  margin-top: 39px;
  margin-bottom: 44px;
}
.guestC {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
}


.buttonBox {
  display: flex;
  flex-direction: column;

  .button {
    text-align: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    background-color: var(--GREEN-ARIA);
    color: white;
    border-radius: 24px;
    gap: 8px;
    width: 220px;
    height: 40px;
    font-size: 16px;
    font-family: 'GothamBold', sans-serif;
    align-self: center;
    margin-top: 10%;
    align-items: center;
  }
}

.guestTitle {
  width: 30%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  margin-left: 5%;
}

.guestBox {
  width: 55%;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5%;
}

.guestTitleText {
  color: var(--BLUE-ARIA);
  font-family: GothamBold, sans-serif;
  font-size: 42px;
  text-align: left;
  margin-left: 14%;
}
.linedashboardGuest {
  height: 3.5px;
  width: 120px;
  border-radius: 100px;
  background-color: var(--GREEN-ARIA);
  margin-top: 0.8%;
  margin-left: 14%;
}

.arrow {
  align-self: center;
  margin-top: 5%;
  width: 200px;
  height: 200px;
}

.guestBox2 {
  border: solid 1px var(--GREEN-ARIA);
  border-radius: 24px;
  width: 778px;
  height: 630px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.guestSectionDescription {
  color: var(--BLUE-ARIA);
  font-family: GothamBold, sans-serif;
  font-size: 30px;
  width: 83%;
  margin-top: 3%;
}

.guestSectionDescription2 {
  color: var(--GREEN-ARIA);
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}

.guestBox2 .form {
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.guestBox2 .forgotPassword {
  color: var(--GREEN-ARIA);
  font-size: 13px;
  font-family: GothamBold, sans-serif;
  margin-top: 4%;
  width: 74%;
  text-align: right;
  align-self: center;
  cursor: pointer;
}

.guesticon {
  margin-top: 10%;
  margin-bottom: 3%;
  width: 105px;
  height: 105px;
  align-self: center;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin-top: 6%;
  width: 70%;
  justify-content: space-between;
}

.buttonCancel {
  background-color: white;
  border: solid 1px var(--GREEN-ARIA);
  border-radius: 24px;
  gap: 8px;
  width: 130px;
  height: 40px;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.05px;
  font-family: GothamBold, sans-serif;
  align-self: center;
  margin-top: 4%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--GREEN-ARIA);
}

.buttonCancel:hover {
  color: #e8e8e8;
}

.buttonCancel:hover::after {
  height: 0;
}

.buttonSave {
  background-color: var(--GREEN-ARIA);
  color: white;
  border-radius: 24px;
  gap: 8px;
  width: 130px;
  height: 40px;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.05px;
  font-family: GothamBold, sans-serif;
  align-self: center;
  margin-top: 4%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.buttonSave:hover {
  color: #e8e8e8;
}

.buttonSave:hover::after {
  height: 0;
}

@media screen and (max-width: 1111px) {
  .guestC .guestTitle {
    width: 100%;
  }

  .guestC .guestBox {
    width: 100%;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .guestC .guestBox.guestBox2 {
    align-self: center;
  }
}

.registerButtonGuest {
  background-color: var(--GREEN-ARIA);
  color: white;
  border-radius: 24px;
  padding: 10px;
  gap: 8px;
  width: 120px;
  height: 40px;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: -0.05px;
  font-family: 'GothamBold', sans-serif;
  justify-content: center;
  margin-top: 5%;
  cursor: pointer;

  &:hover {
    color:white;
    width: 123px;
    font-size: 16px;
    transition: 0.3s ease-in-out;
  }
}

.modalBox {
  display: flex;
  flex-direction: column;

  .text {
    text-align: center;
    font-family: 'MontserratSemiBold', sans-serif;
  }

  .boxicon {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    .icon {
      color: grey;
      align-self: center;
    }
  }
}

.modalBox2 {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p{
    font-family: 'MontserratSemiBold', sans-serif;
    font-size: 1rem;
    color: var(--TEXT-GRAY-DARK);
  }

  .expire {
    margin-top: 1%;
  }

  .continue {
    margin-top: 2%;
  }

  .link {
    align-self: center;
    margin-top: 2%;
    text-decoration: none;
    color: var(--GREEN-ARIA);

    &:hover{
      color: var(--BLUE-ARIA) !important;
      transition:  0.3s ease-in-out;
    }

    &:visited{
      color: var(--GREEN-ARIA);
    }
  }
}



.registerButtonGuest:hover::after {
  height: 0;
}
