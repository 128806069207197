.profileCard {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;

  .profileBox {
    width: 45%;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 29px;
    margin-bottom: 35px;

  }


  .dashTitle2 {
    color: var(--BLUE-ARIA);
    font-family: 'GothamBold', sans-serif;
    font-size: 24px;
    align-self: center;
    text-align: center;
  }

  .profileBox2 {
    border: solid 1px var(--GREEN-ARIA);
    border-radius: 24px;
    width: 440px;
  }

  .profileBox2 .form {
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    padding: 20px 33px 27px 33px;
    gap: 8px;

    .formContent{
      text-align: left;
      display: flex;
      flex-direction: column;

      .formTitle{
        color: #2F2E2E;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto, sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.046px;
        gap: 8px;
      }

      .formUserInfo{
        color: var(--AZUL-ARIA, #01BFD5);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto, sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.046px;
        display: flex;
        padding: 8px 0 17px 0;
        align-items: center;
        flex: 1 0 0;
        align-self: stretch;
        gap: 8px;
      }

      .divider{
        border: 1px solid var(--VERDE-ARIA, #00E6A1);
        margin-bottom: 8px;
      }

    }


  }

  .imgProfile {
    margin-top: 8%;
    margin-bottom: 3%;
  }

}

@media (max-width: 1400px) {

  .profileCard {
    .profileBox {
      width: 100%;
      margin-top: 3%;
      margin-bottom: 3%;
      min-width: 352px;
      .profileBox2{
        width: 320px
      }
    }
  }

}

@media (max-width: 1300px) {

  .profileCard {
    .profileBox {
      width: 100%;
      margin-top: 3%;
      margin-bottom: 3%;
      min-width: 352px;
      .profileBox2{
        width: 356px
      }
    }
  }

}

@media (max-width: 991.98px) {


  .profileCard {
    .profileBox {
      width: 100%;
      margin-top: 3%;
      margin-bottom: 3%;
      min-width: 352px;
      .profileBox2{
        width: auto
      }
    }
  }
}
