.inputInvite {
  width: 100%;
  padding-left: 13px;
  border: solid 1px var(--GREEN-ARIA);
  height: 45px;
  border-radius: 24px;
  font-family: Montserrat, sans-serif;
  color: var(--TEXT-GRAY-DARK);
  font-size: 1.5rem;

  &:focus {
    outline: none;
    border: solid 2px var(--GREEN-ARIA);
  }

}

.selectCountry {
  outline: none;
  width: 80px;
  margin-right: 20px;
  border:none;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px var(--GREEN-ARIA);

    :first-of-type, :last-of-type {
      border:none
    }

    .textSelect {
      font-family: Montserrat, sans-serif;
      font-size: 8px;
      color: var(--TEXT-GRAY-DARK);
      margin-left: 5px;

      span {
        font-size: 10px;
      }
    }

    .itemIcon {
      min-width: 0;
    }
  }
}

.containerTextField {
  width: 60%;
}

.menuItemSelect {
  display: flex;
}
