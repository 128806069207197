.loginContainer {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
}

.modalBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .text {
    padding-left: 10px;
  }

  .closeIcon {
    cursor: pointer;

    .icon {
      color: grey;
      align-self: center;
    }
  }
}

.inputbox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  display: flex;
  justify-content: center;
  cursor: pointer;
  background-color: #00e6a1 !important;
  color: white;
  border-radius: 24px;
  gap: 8px;
  width: 206px;
  height: 40px;
  font-size: 16px;
  font-family: 'GothamBold', sans-serif;
  margin-top: 10%;
  align-items: center;
}

.input {
  padding-left: 13px;
  align-self: center;
  border: solid 1px #00e6a1;
  height: 45px;
  border-radius: 24px;
  margin-top: 13%;
  width: 100%;
  font-family: Roboto, sans-serif;
}

.downloadApp {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  min-width: 450px;
}

.downloadApp .title0 {
  color: var(--BLUE-ARIA);
  font-family: 'GothamBold', sans-serif;
  font-size: 42px;
  text-align: left;
  width: 60%;
  margin-top: 5%;
  align-self: center;
}

.downloadApp .line0 {
  align-self: center;
  width: 60%;
}


.downloadApp .line {
  height: 4px;
  width: 131px;
  border-radius: 100px;
  background-color: #00e6a1;
}

.downloadApp .downloadBox {
  background-color: var(--BLUE-ARIA);
  width: 30vw;
  height: 18vw;
  margin-top: 3%;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
  align-self: center;
}

.downloadApp .downloadBox .title {
  color: white;
  font-family: 'GothamBold', sans-serif;
  font-size: 2.4rem;
  margin-left: 0;
  width: 85%;
  text-align: center;
  align-self: center;
}

.downloadApp .downloadBox .description {
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-size: 1.5rem;
  line-height: 24px;
  text-align: center;
  width: 85%;
  align-self: center;
}

.downloadApp .downloadBox .stores {
  display: flex;
  justify-content: space-between;
  align-self: center;
  gap: 13px;

  img{
    width: 100%;
    height: 100%;
  }
}

.login {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  align-self: center;
  margin-top: 2%;
  margin-bottom: 2%;
}


.login .registerBox {
  border: solid 1px #00e6a1;
  width: 436px;
  align-self: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 24px 24px;
  border-top: 0;
}

.login .tabs {
  height: 60px;
  display: flex;
  width: 436px;
  align-self: center;
}

.login .tabs .createAccount {
  border: solid 1px #00e6a1;
  width: 50%;
  border-bottom: 0;
  border-right-width: 0;
  border-radius: 24px 24px 24px 0;
  background-color: transparent !important;
  cursor: pointer;
  justify-content: center;
}

.login .tabs .createAccount p {
  color: #ccd3d3;
  font-family: 'GothamBold', sans-serif;
  font-size: 18px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login .tabs .createAccount .active {
  color: var(--BLUE-ARIA);
  border-radius: 24px;
  background-color: white;
}

.login .tabs .login2 .active {
  color: var(--BLUE-ARIA);
  background-color: white;
  border-radius: 24px 24px 0 24px;
}

.login .tabs .login2 p {
  color: #ccd3d3;
  font-family: 'GothamBold', sans-serif;
  font-size: 18px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login .tabs .login2 {
  width: 50%;
  border: solid 1px #00e6a1;
  border-radius: 24px 24px 0 24px;
  cursor: pointer;
}

.login .registerBox .title {
  color: var(--BLUE-ARIA);
  font-family: 'GothamBold', sans-serif;
  font-size: 28px;
  width: 75%;
  margin-top: 4%;
  text-align: center;
  align-self: center;
}

.login .registerBox .form {
  margin-top: -1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;

  .invitationCode {
    width: 73%;
    align-self: center;
  }
}

.login .registerBox .form .input {
  border: solid 1px #00e6a1;
  width: 75%;
  height: 45px;
  border-radius: 24px;
  margin-top: 3%;
}

.login .registerBox .form .input[type='text'] {
  padding-left: 4.5%;
}

.login .registerBox .form .input[type='password'] {
  padding-left: 4.5%;
}

.registerButton{
  background-color: #00e6a1 !important;
  margin-bottom:42px !important;
}

.login .registerBox .form .registerButton {
  background-color: #00e6a1 !important;
  color: white;
  border-radius: 24px;
  gap: 8px;
  width: 206px;
  height: 40px;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.05px;
  font-family: 'GothamBold', sans-serif;
  align-self: center;
  margin-top: 4%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.login .registerBox .form .registerButton:hover {
  color: #e8e8e8;
}

.login .registerBox .form .registerButton:hover::after {
  height: 0;
}

.login .registerBox .form .agreeDiv {
  display: flex;
  width: 75%;
  justify-content: space-between;
  margin-top: 8.5%;
}

.login .registerBox .form .agreeDiv .agreeTerms {
  font-size: 13px;
  color: var(--TEXT-GRAY-DARK);
  font-family: 'GothamLight', sans-serif;
  text-align: left;
  margin-left: 5%;
}

.container {
  margin: 0 auto;
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1.5px solid #00e6a1;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  width: 20px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 5px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 4px;
  transform: rotate(-45deg);
  width: 8px;
}

.round input[type='checkbox'] {
  visibility: hidden;
}

.round input[type='checkbox']:checked + label {
  background-color: #00e6a1;
  border-color: #00e6a1;
}

.round input[type='checkbox']:checked + label:after {
  opacity: 1;
}

.login .loginBox {
  border: solid 1px #00e6a1;
  width: 436px;
  height: 477px;
  align-self: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-top: 0;
  border-radius: 0 0 24px 24px;
}

.login .loginBox .title {
  color: var(--BLUE-ARIA);
  font-family: 'GothamBold', sans-serif;
  font-size: 32px;
  width: 75%;
  margin-top: 8%;
  text-align: center;
  align-self: center;
}

.login .loginBox .form {
  margin-top: 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.login .loginBox .forgotPassword {
  color: #00e6a1;
  font-size: 13px;
  font-family: 'GothamBold', sans-serif;
  margin-top: 4%;
  width: 74%;
  text-align: right;
  align-self: center;
  cursor: pointer;
}

.login .loginBox .form .input {
  border: solid 1px #00e6a1;
  width: 75%;
  height: 45px;
  border-radius: 24px;
  margin-top: 3.5%;
}

.login .loginBox .form .input[type='text'],
.login .loginBox .form .input[type='email'],
.login .loginBox .form .input[type='password'] {
  padding-left: 4.5%;
}

.login .loginBox .form .input[type='email'] {
  padding-left: 4.5%;
}

.login .loginBox .form .registerButton {

  color: white;
  border-radius: 24px;
  gap: 8px;
  width: 206px;
  height: 40px;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.05px;
  font-family: 'GothamBold', sans-serif;
  align-self: center;
  margin-top: 5.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.login .loginBox .form .registerButton:hover {
  color: #e8e8e8;
}

.login .loginBox .form .registerButton:hover::after {
  height: 0;
}

.arrow {
  align-self: center;
  width: 100px;
  height: 100px;
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .downloadApp .downloadBox {
    background-color: var(--BLUE-ARIA);
    width: 40vw;
    height: 30vw;
  }
  .downloadApp .downloadBox .title {
    font-size: 1.5rem;
  }

  .downloadApp .downloadBox .description {
    font-size: 0.9rem;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .downloadApp {
    width: 100%;
  }
  .loginContainer {
    justify-content: center;
  }

  .downloadApp .downloadBox {
    background-color: var(--BLUE-ARIA);
    width: 50vw;
    height: 40vw;
  }
  .downloadApp .downloadBox .title {
    font-size: 2.2rem;
  }

  .downloadApp .downloadBox .description {
    font-size: 1.2rem;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .downloadApp .downloadBox {
    background-color: var(--BLUE-ARIA);
    width: 60vw;
    height: 50vw;
  }
  .downloadApp .downloadBox .title {
    font-size: 1.8rem;
  }

  .downloadApp .downloadBox .description {
    font-size: 1rem;
  }
}

@media (max-width: 575.98px) {
  .downloadApp .downloadBox {
    background-color: var(--BLUE-ARIA);
    width: 75vw;
    height: 65vw;
  }
  .downloadApp .downloadBox .title {
    font-size: 1.9rem;
  }

  .downloadApp .downloadBox .description {
    font-size: 1rem;
  }
}





