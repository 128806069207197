.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 80px;
  background: #F4F4F4;
  li {
    list-style: none;
    padding: 0 20px;
    position: relative;
    cursor: pointer;
  }
}

.navRegisteredGuest{
  ul{
    li{
      list-style: none;
      padding: 0 20px;
      position: relative;
      cursor: pointer;
      &:last-child{
        margin-left: auto;
      }
    }
  }
}

.navBar {
  display: flex;
  align-items: center;
  width: 100%;

}

.navBarPatientList{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
}

.defaultLinks{
  color: var(--TEXT-GRAY-DARK) !important;
  font-size: 14px !important;
  text-decoration: none;
  font-weight: 700;
  line-height: 25px;

}

.patientList{
  color: var(--TEXT-GRAY-DARK) !important;
  font-size: 14px !important;
  text-decoration: none;
  font-weight: 700;
  line-height: 25px;
  display:flex;
  justify-content: flex-end;
  margin-left:auto;
  width: 300px
}

.active {
  color: var(--BLUE-ARIA) !important;
}

.links {
  text-decoration: none !important;
  font-weight: 700 !important;
  transition: 0.3s ease-in-out !important;
  font-family: 'Montserrat', sans-serif !important;
  line-height: 25px !important;
  display:flex;
  align-items: center;

  &:hover{
    font-size: 16px !important;
    transition:  0.3s ease-in-out !important;
  }

}

.guestLi{
  display: flex;
  align-items: center;

  img{
    margin-right: 10px;
  }
}

.navbar li a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: #1d1d1b;
  transition: 0.3s ease-in-out;
  font-family: 'Montserrat', sans-serif;
  line-height: 25px;
}


@media screen and (max-width: 769px) {
  .navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 90px;
    right: -300px;
    width: 300px;
    height: 100vh;
    background: white;
    padding: 40px 0 0 10px;
    transition: 0.3s ease-in-out;
  }

  .navbar.active {
    right: 0;
  }

  .navbar li {
    margin-bottom: 25px;
  }

}

