.containerInside {
  flex-direction: column;
  width: auto;
  min-width: 469px;
  display: flex;
  padding: 24px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
}

.agoraContainer {
  display: flex;
  justify-content: center;
  padding-top: 20%;
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 24px;
  padding-left: 24px;
}

.alert {
  height: 90px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 3.5%;
}

.contentIcon {
  display: flex;
  justify-content: flex-end;
}

.icon {
  margin-right: 1%;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.contentTitle {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 469px;
}

.title {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #515151;
  text-align: center;
}

.containerForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.buttonCancel {
  display: flex;
  width: 102px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 24px;
  border: 1px solid #00e6a1;
  background: #fff;
  color: #00e6a1;
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: none;
}
