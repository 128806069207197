.privacyContainer {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;

  .privacyContainer2 {
    width: 80%;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-self: center;

    .privacyTitle {
      color: var(--BLUE-ARIA);
      font-family: 'GothamBold', sans-serif;
      font-size: 42px;
      text-align: left;
      margin-top: 4%;
    }

    .linedashboard {
      height: 3.5px;
      width: 90px;
      border-radius: 100px;
      background-color: var(--GREEN-ARIA);
      margin-top: 0.8%;
    }
  }

  .textbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2.5%;

    .title {
      font-family: 'GothamBold', sans-serif;
      margin-top: 2%;
      color: var(--TEXT-GRAY-DARK);
    }

    .description {
      text-align: left;
      margin-top: 0.5%;
      color: var(--TEXT-GRAY-DARK);
    }
  }
}
