
.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding: 44px 45px;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;

}

.navbar li {
  list-style: none;
  padding: 0 20px;
  position: relative;
  cursor: pointer;
}

.registerButton {
  text-decoration: none;
  color: var(--TEXT-GRAY-DARK) !important;
  font-size: 14px !important;
  line-height: 25px;
  letter-spacing: -0.05px;

}

.loginButton {
  color: #00e6a1 !important;
  text-decoration: none;
  font-size: 14px !important;
  font-weight: 700;
  line-height: 25px;

}

.defaultLinks{
    color: var(--TEXT-GRAY-DARK) !important;
    font-size: 14px !important;
    text-decoration: none;
    font-weight: 700;
    line-height: 25px;
}

.guestButton {
  color: var(--TEXT-GRAY-DARK) !important;
  font-size: 14px !important;
  text-decoration: none;
  font-weight: 700;
  line-height: 25px;
}

.aboutUs{
  color: var(--TEXT-GRAY-DARK) !important;
  font-size: 14px !important;
}

.loginIcon {
  margin-top: 0.5%;
}

.active {
  background: #17cf97;
  font-size: 16px !important;
  border-radius: 24px;
  padding: 10px;
  gap: 8px;
  width: 101px;
  height: 40px;
  color: #fff !important;

  &:hover {
    height: 0;
  }

  &:hover::after {

    height: 0 !important;

  }


}

.links {
  text-decoration: none !important;
  font-weight: 700 !important;
  transition: 0.3s ease-in-out !important;
  font-family: 'Montserrat', sans-serif !important;
  line-height: 25px !important;

  &:hover{
    font-size: 16px !important;
    transition:  0.3s ease-in-out !important;
  }

  &:hover::after {
    content: '';
    width: 30%;
    height: 2px;
    background: #17cf97;
    position: absolute;
    bottom: -4px;
    left: 20px;
  }

}


.mobile {
  display: none;

  > i {
    color: #fff;
    align-items: center;
  }
}

@media screen and (max-width: 769px) {
  .navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 90px;
    right: -300px;
    width: 300px;
    height: 100vh;
    background: white;
    padding: 40px 0 0 10px;
    transition: 0.3s ease-in-out;
    li {
      margin-bottom: 25px;
    }


    &.active {
      right: 0;
    }
  }

  .mobile {
    display: block;

    > i {
      font-size: 24px;
      cursor: pointer;
    }
  }
}

.logoContainer {
  text-decoration: none;
  cursor: pointer;
}

.logoBox {
  display: flex;
}

.textBox {
  align-items: center;
  display: flex;
  margin-left: 11%;
}

.logoText {
  color: #1d1d1b;
  font-family: 'GothamLight', sans-serif;
  letter-spacing: 4px;
  font-size: 17px;
}
